import React, { Component } from "react";
import { areaServices } from "../../services/autotrimitra/areaServices";
import Select from "antd/lib/select";

class AreaDistrictSelect extends Component<any, any> {
  private initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      dataDistrict: [],
      selectDistrictValue: null,
    };
    this.state = { ...this.initState };
  }

  fetchDistrictData = (cityCode: any) => {
    if (cityCode) {
      try {
        areaServices
          .getDistrict({
            cityCode: cityCode,
          })
          .then((dataFetchDistrict) => {
            const dataForState: any = dataFetchDistrict.data.data.map((city: { code: string; name: string }) => ({
              value: city.code,
              name: city.name,
            }));
            this.setState({
              dataDistrict: dataForState,
              selectDistrictValue: this.props.districtName ?? null,
            });
          });
      } catch (e) {
        this.setState({
          dataDistrict: [],
          selectDistrictValue: null,
        });
      }
    } else {
      this.setState({
        dataDistrict: [],
        selectDistrictValue: null,
      });
    }
  };

  onChange = (value: any, option: any) => {
    this.setState({
      selectDistrictValue: value,
    });

    this.props.onAreaDistrictChangeProps(option);
  };

  async componentDidMount() {
    this.fetchDistrictData(this.props.cityCode);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.cityCode !== prevProps.cityCode) {
      this.fetchDistrictData(this.props.cityCode);
    }
  }

  render() {
    return (
      <Select showSearch placeholder="Pilih Kecamatan" optionFilterProp="children" style={{ width: "100%" }} onChange={this.onChange} filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={this.props.districtName ?? this.state.selectDistrictValue}>
        {this.state.dataDistrict.map((item: any, i: number) => (
          <Select.Option key={i} value={item?.value}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AreaDistrictSelect;
