import React, { Component } from "react";
import { areaServices } from "../../services/autotrimitra/areaServices";
import Select from "antd/lib/select";

class AreaSubdistrictSelect extends Component<any, any> {
  private initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      dataSubdistrict: [],
      selectSubdistrictValue: null,
    };
    this.state = { ...this.initState };
  }

  fetchSubdistrictData = (districtCode: any) => {
    if (districtCode) {
      try {
        areaServices
          .getSubdistrict({
            districtCode: districtCode,
          })
          .then((dataFetchSubdistrict: { data: { data: { code: string; name: string; postal_code: any }[] } }) => {
            const dataForState: any = dataFetchSubdistrict.data.data.map((subDistrict: { code: string; name: string; postal_code: any }) => ({
              value: subDistrict.code,
              name: subDistrict.name,
              postalCode: subDistrict.postal_code,
            }));
            this.setState({
              dataSubdistrict: dataForState,
              selectSubdistrictValue: this.props.subdistrictName ?? null,
            });
          });
      } catch (e) {
        this.setState({
          dataSubdistrict: [],
          selectSubdistrictValue: null,
        });
      }
    } else {
      this.setState({
        dataSubdistrict: [],
        selectSubdistrictValue: null,
      });
    }
  };

  onChange = (value: any, option: any) => {
    this.setState({
      selectSubdistrictValue: value,
    });
    option.postalCode = this.state.dataSubdistrict[option.key].postalCode ? this.state.dataSubdistrict[option.key]?.postalCode[0] : "";
    this.props.onAreaSubdistrictChangeProps(option);
  };

  async componentDidMount() {
    this.fetchSubdistrictData(this.props.districtCode);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.districtCode !== prevProps.districtCode) {
      this.fetchSubdistrictData(this.props.districtCode);
    }
  }

  render() {
    return (
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Pilih Kelurahan"
        optionFilterProp="children"
        onChange={this.onChange}
        filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={this.props.subdistrictName ?? this.state.selectSubdistrictValue}
      >
        {this.state.dataSubdistrict.map((item: any, i: number) => (
          <Select.Option key={i} value={item?.value}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AreaSubdistrictSelect;
