import React, {Component} from "react";
import VehicleBrandSelect from "../vehicle/VehicleBrandSelect";
import VehicleModelSelect from "../vehicle/VehicleModelSelect";
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import {Card, Select, Form, Input, Steps, Button, Modal, Slider, Upload, Image} from "antd";
// import {bqService} from "../../services/gcpBridgingBq/BqService";
import {b2bService} from "../../services/b2b/B2bService";
import AreaDistrictSelect from "../area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../area/AreaSubdistrictSelect";
import CustomerOccupation from "../biodata/CustomerOccupation";
import {UploadOutlined} from "@ant-design/icons";

const {Step} = Steps;

class VehiclePledgeForm extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            currentStep: 0,

            filterVehicleCategory: "car",
            filterVehicleBrandUuid: null,
            filterVehicleModelUuid: null,

            idCardNumber: "",
            familyRegisterNumber: "",
            fullName: "",
            phoneNumber: "",
            occupationCode: null,
            occupationName: "",

            provinceCodeCurrent: null,
            provinceNameCurrent: null,
            cityCodeCurrent: null,
            cityNameCurrent: null,
            districtCodeCurrent: null,
            districtNameCurrent: null,
            subdistrictCodeCurrent: null,
            subdistrictNameCurrent: null,
            postalCodeCurrent: null,

            licensePlate: "",
            vehicleBrand: null,
            vehicleModel: null,
            modelUuid: null,
            modelName: null,
            modelClass: null,
            vehicleYear: new Date().getFullYear(),

            mileage: null,
            requiredAmountCar: 50,
            requiredAmountMotorcycle: 1,
            tenor: 3,

            imageCard: [],
            imageCardIsValid: false,
            imageCardPreview: "",

            imageFamilyRegister: [],
            imageFamilyRegisterIsValid: false,
            imageFamilyRegisterPreview: "",

            imageDocumentVehicle: [],
            imageDocumentVehicleIsValid: false,
            imageDocumentVehiclePreview: "",

            loading: 0,
        };
        this.state = {...this.initState};
    }

    onFieldChange = <T extends keyof Pick<any, "idCardNumber" | "familyRegisterNumber" | "licensePlate" | "mileage" | "vehicleYear" | "filterVehicleCategory" | "fullName" | "phoneNumber" | "postalCodeCurrent">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, "");
                break;
            case "familyRegisterNumber":
                currentState.familyRegisterNumber = value.replace(/[^0-9.]/g, "");
                break;
            case "licensePlate":
                currentState.licensePlate = value?.toUpperCase();
                break;
            case "vehicleYear":
                currentState.vehicleYear = value;
                break;
            case "fullName":
                currentState.fullName = value?.toUpperCase();
                break;
            case "filterVehicleCategory":
                currentState.filterVehicleCategory = value;
                currentState.filterVehicleBrandUuid = null;
                currentState.filterVehicleModelUuid = null;
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, "");
                break;
            case "mileage":
                currentState.mileage = value.replace(/[^0-9.]/g, "");
                break;
            case "postalCodeCurrent":
                currentState.postalCodeCurrent = value;
                break;
        }
        if (target === "phoneNumber") {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== "08") {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    };

    onOccupationChange = (data: any) => {
        this.setState({
            occupationCode: data.value,
            occupationName: data.children,
        });
    };

    onVehicleBrandChange = (data: any) => {
        this.setState({
            vehicleBrand: data?.children,
            filterVehicleBrandUuid: data?.key,
        });
    };

    onVehicleModelChange = (data: any) => {
        this.setState({
            modelUuid: data.value,
            filterVehicleModelUuid: data?.value,
            modelName: data.children,
            modelClass: data.class,
        });
    };

    onAreaProvinceCurrentChange = (data: any) => {
        this.setState({
            provinceCodeCurrent: data.value,
            provinceNameCurrent: data.children,
            cityCodeCurrent: null,
            cityNameCurrent: null,
        });
    };

    onAreaCityCurrentChange = (data: any) => {
        this.setState({
            cityCodeCurrent: data.value,
            cityNameCurrent: data.children,
        });
    };

    onAreaDistrictCurrentChange = (data: any) => {
        this.setState({
            districtCodeCurrent: data.value,
            districtNameCurrent: data.children,
            subdistrictCodeCurrent: "",
            subdistrictNameCurrent: "",
            postalCodeCurrent: "",
        });
    };

    onAreaSubdistrictCurrentChange = (data: any) => {
        this.setState({
            subdistrictCodeCurrent: data.value,
            subdistrictNameCurrent: data.children,
            postalCodeCurrent: data.postalCode,
        });
    };

    onStepChange = (current: number) => {
        this.setState({currentStep: current});
    };

    beforeUploadIdCardImage = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({
                title: "Validation Error",
                content: "Format File Bukan JPG/PNG",
            });
        }
        if (isJpgOrPng) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    imageCardPreview: event.target.result,
                    imageCard: file,
                    imageCardIsValid: true,
                });
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({
                imageCardIsValid: false,
            });
        }
        return false;
    };

    beforeUploadFamilyRegisterImage = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({
                title: "Validation Error",
                content: "Format File Bukan JPG/PNG",
            });
        }
        if (isJpgOrPng) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    imageFamilyRegisterPreview: event.target.result,
                    imageFamilyRegister: file,
                    imageFamilyRegisterIsValid: true,
                });
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({
                imageCardIsValid: false,
            });
        }
        return false;
    };

    beforeUploadDocumentVehicleImage = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({
                title: "Validation Error",
                content: "Format File Bukan JPG/PNG",
            });
        }
        if (isJpgOrPng) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    imageDocumentVehiclePreview: event.target.result,
                    imageDocumentVehicle: file,
                    imageDocumentVehicleIsValid: true,
                });
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({
                imageCardIsValid: false,
            });
        }
        return false;
    };

    sendData = async () => {
        const dataB2b = {
            source: "danabpkb",
            name: this.state.fullName?.toUpperCase(),
            phoneNumber: this.state.phoneNumber,
            vehicle: {
                license_plate: this.state.licensePlate,
                brand: {
                    name: this.state.vehicleBrand,
                    uuid: this.state.filterVehicleBrandUuid,
                },
                model: {
                    name: this.state.modelName,
                    uuid: this.state.modelUuid,
                    category: this.state.filterVehicleCategory,
                },
                condition: "USED",
                year: this.state.vehicleYear,
                mileage: this.state.mileage,
            },
            scheme: {
                amount: this.state.filterVehicleCategory === "car" ? Number(this.state.requiredAmountCar * 1000000) : Number(this.state.requiredAmountMotorcycle * 1000000),
                tenor: this.state.tenor,
            },
            id_card: {
                id_card_number: this.state.idCardNumber,
                id_card_image: this.state.imageCard,
                full_name: this.state.fullName?.toUpperCase(),
                occupation_code: this.state.occupationCode,
                occupation: this.state.occupationName,
            },
            id_card_address: {
                province: {code: this.state.provinceCodeCurrent, name: this.state.provinceNameCurrent},
                city: {code: this.state.cityCodeCurrent, name: this.state.cityNameCurrent},
                district: {code: this.state.districtCodeCurrent, name: this.state.districtNameCurrent},
                sub_district: {code: this.state.subdistrictCodeCurrent, name: this.state.subdistrictNameCurrent},
                zip_code: this.state.postalCodeCurrent
            },
            domicile_address: {
                province: {code: this.state.provinceCodeCurrent, name: this.state.provinceNameCurrent},
                city: {code: this.state.cityCodeCurrent, name: this.state.cityNameCurrent},
                district: {code: this.state.districtCodeCurrent, name: this.state.districtNameCurrent},
                sub_district: {code: this.state.subdistrictCodeCurrent, name: this.state.subdistrictNameCurrent},
                zip_code: this.state.postalCodeCurrent
            },
            family_register: {
                family_register_image: this.state.imageFamilyRegister,
                family_register_number: this.state.familyRegisterNumber
            },
            vehicle_registration: {
                vehicle_registration_image: this.state.imageDocumentVehicle
            },
        };

        if (!dataB2b.domicile_address.zip_code || !dataB2b.id_card.id_card_number || !dataB2b.id_card.full_name) {
            return Modal.error({title: "Proses Gagal", content: "Mohon lengkapi data diri"});
        }
        if (!this.state.imageCardIsValid) {
            return Modal.error({title: "Proses Gagal", content: "Mohon lengkapi image ktp"});
        }
        if (!this.state.imageFamilyRegisterIsValid) {
            return Modal.error({title: "Proses Gagal", content: "Mohon lengkapi KK"});
        }
        if (!this.state.imageDocumentVehicleIsValid) {
            return Modal.error({title: "Proses Gagal", content: "Mohon lengkapi SSTNK"});
        }
        if (dataB2b.phoneNumber.length < 8) {
            return Modal.error({title: "Proses Gagal", content: "nomor telpon tidak valid"});
        }


        if (!dataB2b.vehicle.model.uuid || !dataB2b.vehicle.year || !dataB2b.scheme.amount || !dataB2b.scheme.tenor || !dataB2b.vehicle.license_plate) {
            return Modal.error({title: "Proses Gagal", content: "Mohon lengkapi data kendaraan"});
        }

        this.setState({loading: true});

        try {
            await b2bService.sendData(dataB2b);
            Modal.success({
                title: "Proses Sukses",
                content: "Terimakasih telah melakukan apply data. kami akan menghubungi anda dalam beberapa waktu kedepan",
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (e) {
            await this.promisedSetState({
                loading: false,
            });
            Modal.error({title: "Error", content: " " + e});
        }
    };

    onChangeSliderCar = (value: any) => {
        this.setState({requiredAmountCar: value});
    };
    onChangeSliderMotorcycle = (value: any) => {
        this.setState({requiredAmountMotorcycle: value});
    };
    onChangeTenor = (value: any) => {
        this.setState({tenor: value});
    };

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const marksCar = {50: "50jt", 100: "100jt", 250: "250jt", 500: "500jt"};
        const marksMotorcycle = {1: "1jt", 10: "10jt", 25: "25jt", 50: "50jt"};
        const marksTenor = {3: "3bulan", 12: "12bulan", 36: "36bulan", 60: "60bulan"};

        const years = [];
        for (let i = this.state.vehicleYear; i >= this.state.vehicleYear - 10; i--) {
            years.push(i.toString());
        }
        return (
            <>
                <Card title="Tertarik Dengan Pinjaman" hoverable={true} style={{width: "100%", marginTop: "-120px"}}>
                    <div style={{marginBottom: 20}}>
                        <Steps current={this.state.currentStep} onChange={this.onStepChange}>
                            <Step title="Data Diri"/>
                            <Step title="Data Kendaraan"/>
                        </Steps>
                    </div>

                    <div style={this.state.currentStep === 0 ? {} : {display: "none"}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                            <Input value={this.state.fullName} onChange={(event) => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor KTP *</i>
                            <Input maxLength={16} value={this.state.idCardNumber} placeholder="Nomor Sesuai KTP" onChange={(event) => this.onFieldChange("idCardNumber", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor Kartu Keluarga *</i>
                            <Input maxLength={32} value={this.state.familyRegisterNumber} placeholder="Nomor Kartu Keluarga" onChange={(event) => this.onFieldChange("familyRegisterNumber", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor Telpon *</i>
                            <Input value={this.state.phoneNumber} maxLength={17} placeholder="Format: 08xxxxxxxx" onChange={(event) => this.onFieldChange("phoneNumber", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Pekerjaan *</i>
                            <CustomerOccupation onCustomerOccupationChangeProps={this.onOccupationChange} value={this.state.occupationCode}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi Saat Ini *</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceCurrentChange} country={"idn"} provinceCode={this.state.provinceCodeCurrent} provinceName={this.state.provinceNameCurrent}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Saat Ini *</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityCurrentChange} provinceCode={this.state.provinceCodeCurrent} cityName={this.state.cityNameCurrent}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kecamatan Saat Ini *</i>
                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictCurrentChange} cityCode={this.state.cityCodeCurrent} districtName={this.state.districtNameCurrent}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kelurahan Saat Ini *</i>
                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictCurrentChange} districtCode={this.state.districtCodeCurrent} subdistrictName={this.state.subdistrictNameCurrent}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Pos Saat Ini *</i>
                            <Input value={this.state.postalCodeCurrent} placeholder="Kodepos" onChange={(event) => this.onFieldChange("postalCodeCurrent", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Foto KTP *</i>
                            <Form.Item>
                                <Upload maxCount={1} showUploadList={false} beforeUpload={this.beforeUploadIdCardImage}>
                                    <Button icon={<UploadOutlined/>} loading={this.state.loadingButton} style={{width: "100%"}}>
                                        Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            {this.state.imageCardPreview && <Image width={"inherit"} src={this.state.imageCardPreview}/>}
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Foto Kartu Keluarga *</i>
                            <Form.Item>
                                <Upload maxCount={1} showUploadList={false} beforeUpload={this.beforeUploadFamilyRegisterImage}>
                                    <Button icon={<UploadOutlined/>} loading={this.state.loadingButton} style={{width: "100%"}}>
                                        Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            {this.state.imageFamilyRegisterPreview && <Image width={"inherit"} src={this.state.imageFamilyRegisterPreview}/>}
                        </Form.Item>
                        <Button type={"primary"} onClick={() => this.setState({currentStep: 1})}>
                            Next
                        </Button>
                    </div>
                    <div style={this.state.currentStep === 1 ? {} : {display: "none"}}>
                        <Form.Item>
                            <i className={`small-text-grey`}>Kategori Kendaraan*</i>
                            <Select showSearch placeholder="Pilih Kategori Kendaraan" optionFilterProp="children" onChange={(value) => this.onFieldChange("filterVehicleCategory", value)} value={this.state.filterVehicleCategory}>
                                <Select.Option key={`car`} value={`car`}>{`Mobil`}</Select.Option>
                                <Select.Option key={`motorcycle`} value={`motorcycle`}>{`Motor`}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Merk Kendaraan*</i>
                            <VehicleBrandSelect onVehicleBrandChangeProps={this.onVehicleBrandChange} category={this.state.filterVehicleCategory}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Model Kendaraan*</i>
                            <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} brandCode={this.state.filterVehicleBrandUuid} category={this.state.filterVehicleCategory}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Tahun Kendaraan *</i>
                            <Select onChange={(value) => this.onFieldChange("vehicleYear", value)} value={this.state.vehicleYear} style={{width: "100%"}} placeholder={`Tahun Kendaraan`}>
                                {years.map((item: any, i: number) => {
                                    return (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor Polisi *</i>
                            <Input maxLength={16} value={this.state.licensePlate} placeholder="Nomor Polisi" onChange={(event) => this.onFieldChange("licensePlate", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kilometer Kendaraan</i>
                            <Input maxLength={16} value={this.state.mileage} placeholder="Kilometer kendaraan" onChange={(event) => this.onFieldChange("mileage", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Foto STNK *</i>
                            <Form.Item>
                                <Upload maxCount={1} showUploadList={false} beforeUpload={this.beforeUploadDocumentVehicleImage}>
                                    <Button icon={<UploadOutlined/>} loading={this.state.loadingButton} style={{width: "100%"}}>
                                        Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            {this.state.imageDocumentVehiclePreview && <Image width={"inherit"} src={this.state.imageDocumentVehiclePreview}/>}
                        </Form.Item>
                        <Form.Item style={{marginTop: 10}}>
                            <i className={`text-small-grey-nopadding`}>Dana yang dibutuhkan *</i>
                            <div style={this.state.filterVehicleCategory === "car" ? {} : {display: "none"}}>
                                <Slider min={50} max={500} step={10} marks={marksCar} value={this.state.requiredAmountCar} onChange={this.onChangeSliderCar}/>
                            </div>
                            <div style={this.state.filterVehicleCategory === "motorcycle" ? {} : {display: "none"}}>
                                <Slider min={1} max={50} step={5} marks={marksMotorcycle} value={this.state.requiredAmountMotorCycle} onChange={this.onChangeSliderMotorcycle}/>
                            </div>
                        </Form.Item>
                        <Form.Item style={{marginTop: 10}}>
                            <i className={`text-small-grey-nopadding`}>Tenor*</i>
                            <Slider min={3} max={60} step={3} marks={marksTenor} value={this.state.tenor} onChange={this.onChangeTenor}/>
                        </Form.Item>

                        <Button onClick={() => this.sendData()} loading={this.state.loading} type={"primary"}>
                            Kirim Data
                        </Button>
                    </div>
                </Card>
            </>
        );
    }
}

export default VehiclePledgeForm;
